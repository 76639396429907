import Status from '@/libs/models/Status'
import Company from '@/libs/models/Company'

const filterOptions = async () => {
  try {
    const response = await Status.whereIn('id', [1, 2, 3, 4]).get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}


const filterOptionsCompany = async () => {
  try {
    const response = await Company.where({
      type_integration_non_null: true
    }).get()
    return response.data
  } catch (err) {
    console.error(err)
    return err
  }
}

async function columnsValue() {
  const columns = [
    {
      label: 'Estado',
      field: 'status.id',
      tdClass: 'text-center',
      width: '220px',
      sortable: false,
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Selecciona un estado',
        filterDropdownItems: await filterOptions(),
      },
    },
    {
      label: 'Nombre',
      field: 'name',
      firstSortType: 'asc',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Descripción',
      field: 'description',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Empresa',
      field: 'company.name',
      firstSortType: 'asc',
      width: '190px',
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Selecciona una empresa',
        filterDropdownItems: await filterOptionsCompany(),
      },
    },
    {
      label: 'Id documento soap',
      field: 'homolog_id',
      width: '190px',
      filterOptions: {
        customFilter: false,
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
  ]
  return columns
}

export { columnsValue, filterOptions }

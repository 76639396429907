<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="lg"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="CompanyForm"
          >
            <b-form @submit.prevent>
              <vue-errors
                v-if="isNotifmsg"
                :notifmsg="notifmsg"
              />
              <b-row>
                <b-col
                  cols="12"
                >
                  <div
                    class="text-center"
                    @click="ActivateFormFile"
                  >
                    <h6>Foto de empresa</h6>
                    <b-img
                      thumbnail
                      fluid
                      :src="getFilePath"
                    />
                    <h6>Presiona la foto para cambiarla</h6>
                  </div>
                  <b-form-group
                    label-for="companies-photo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="ext:jpg,jpeg,png"
                      name="photo"
                    >
                      <b-form-file
                        id="fileInput"
                        ref="file-input"
                        v-model="photo"
                        class="mb-2"
                        placeholder="Selecciona un archivo"
                        style="visibility: hidden;position: absolute;top: 0;left: -5000px;"
                        accept="image/jpeg, image/png, image/jpeg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="typecontract-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        id="CompanyForm-name"
                        v-model="name"
                        name="CompanyForm-name"
                        placeholder="Nombre de la compañia"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Descripción"
                    label-for="CompanyForm-description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Descripción"
                      vid="Descripción"
                      rules=""
                    >
                      <b-form-input
                        id="CompanyForm-description"
                        v-model="description"
                        name="CompanyForm-name"
                        placeholder="Una breve descipción"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Tipo de integración"
                    label-for="CompanyForm-type_integracion"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <b-form-select
                        v-model="type_integration"
                        :state="errors.length > 0 ? false : null"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :options="optionsTypeIntegration"
                      >
                        <template #first>
                          <b-form-select-option :value="null">
                            Selecciona una opción
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Estado"
                    label-for="CompanyForm-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <b-form-select
                        v-model="status_id"
                        :state="errors.length > 0 ? false : null"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :options="optionsStatus"
                      >
                        <template #first>
                          <b-form-select-option :value="null">
                            Selecciona una opción
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Url"
                    label-for="CompanyForm-url"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="url"
                      vid="url"
                      rules=""
                    >
                      <b-form-input
                        id="CompanyForm-url"
                        v-model="url"
                        name="CompanyForm-url"
                        placeholder="URL de la integracion"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="user">User</label>
                    </div>
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="user"
                        v-model="user"
                        :type="keyFieldType"
                        class="form-control-merge"
                        name="CompanyForm-user"
                        placeholder="User"
                        autocomplete="new-password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="keyToggleIcon"
                          @click="togglekeyVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="password">Password</label>
                    </div>
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="consumerIdFieldType"
                        class="form-control-merge"
                        name="CompanyForm-password"
                        placeholder="Password"
                        autocomplete="new-password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="consumerIdToggleIcon"
                          @click="toggleConsumerIdVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  v-if="type_integration == 'sisorg'"
                >
                  <b-form-group
                    label="Codigo de OpcionPagoID"
                    label-for="company-OpcionPagoID"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="OpcionPagoID"
                      vid="OpcionPagoID"
                      rules=""
                    >
                      <b-form-input
                        id="company-OpcionPagoID"
                        v-model="OpcionPagoID"
                        name="company-OpcionPagoID"
                        placeholder="OpcionPagoID proporcionado por la integración"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  v-if="type_integration == 'sisorg'"
                >
                  <b-form-group
                    label="Codigo de MedioPagoID"
                    label-for="company-MedioPagoID"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="MedioPagoID"
                      vid="MedioPagoID"
                      rules=""
                    >
                      <b-form-input
                        id="company-MedioPagoID"
                        v-model="MedioPagoID"
                        name="company-MedioPagoID"
                        placeholder="MedioPagoID proporcionado por la integración"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group
                    label="Coste por transacción"
                    label-for="company-transactionfee"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="transactionfee"
                      vid="transactionfee"
                      rules=""
                    >
                      <cleave
                        id="company-transactionfee"
                        v-model="transaction_fee"
                        class="form-control"
                        name="company-transactionfee"
                        :raw="true"
                        :options="number"
                        placeholder="Escribe el coste por transacción en PESOS colombianos"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormSelect, BFormSelectOption, BInputGroup, BInputGroupAppend, BFormFile, BImg, BModal,
} from 'bootstrap-vue'
import AlertErrors from '@/components/Errors/Alert.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Status from '@/libs/models/Status'
import Company from '@/libs/models/Company'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BImg,
    BModal,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    'vue-errors': AlertErrors,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      // data
      name: null,
      description: null,
      status_id: null,
      type_integration: null,
      user: null,
      password: null,
      OpcionPagoID: null,
      MedioPagoID: null,
      url: null,
      photo: null,
      transaction_fee: null,
      optionsStatus: [],
      optionsTypeIntegration: [
        {
            "value": "sisorg",
            "text": "Sisorg",
        },
        {
            "value": "brasilia",
            "text": "Brasilia",
        },
      ],
      id: null,
      value: [],
      notifmsg: null,
      isNotifmsg: false,
      keyFieldType: 'password',
      consumerIdFieldType: 'password',
      getFilePath: `${process.env.VUE_APP_URL_STORAGE}/companies/default.png`,

      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },

      // validation rules
      required,
    }
  },
  computed: {
    title() {
      return Object.keys(this.infoData).length > 0 ? 'Editar compañia' : 'Crear compañia'
    },
    keyToggleIcon() {
      return this.keyFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    consumerIdToggleIcon() {
      return this.consumerIdFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    photo(value) {
      this.getFilePath = URL.createObjectURL(value)
      if (value && value !== 'companies/default.png' && Object.keys(this.infoData).length > 0) {
        this.updatePhoto()
      }
    },
  },
  created() {
    if (Object.keys(this.infoData).length > 0) {
      this.getFilePath = `${process.env.VUE_APP_URL_STORAGE}${this.infoData.photo}`
      this.name = this.infoData.name
      this.description = this.infoData.description
      this.status_id = this.infoData.status_id
      this.user = this.infoData.user
      this.password = this.infoData.password
      this.OpcionPagoID = this.infoData.OpcionPagoID
      this.MedioPagoID = this.infoData.MedioPagoID
      this.url = this.infoData.url
      this.id = this.infoData.id
      this.transaction_fee = this.infoData.cost_per_transaction
      this.type_integration = this.infoData.type_integration
    }
  },
  async mounted() {
    this.getOptionsStatus()
  },
  methods: {
    ActivateFormFile() {
      document.getElementById('fileInput').click()
    },
    close() {
      this.$emit('close')
    },
    togglekeyVisibility() {
      this.keyFieldType = this.keyFieldType === 'password' ? 'text' : 'password'
    },
    toggleConsumerIdVisibility() {
      this.consumerIdFieldType = this.consumerIdFieldType === 'password' ? 'text' : 'password'
    },
    async getOptionsStatus() {
      const { data } = await Status.whereIn('id', [1, 2, 3, 4]).get()
      this.optionsStatus = data
    },
    async update() {
      const model = await Company.find(this.id)
      model.name = this.name
      model.description = this.description
      model.status_id = this.status_id
      model.user = this.user
      model.password = this.password
      model.OpcionPagoID = this.OpcionPagoID
      model.MedioPagoID = this.MedioPagoID
      model.url = this.url
      model.type_integration = this.type_integration
      model.cost_per_transaction = this.transaction_fee
      model.save().then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.close()
      }).catch(error => {
        this.isNotifmsg = true
        this.notifmsg = error.response.data.errors
      })
    },
    create() {
      const company = new Company({
        photo: this.photo,
        name: this.name,
        description: this.description,
        status_id: this.status_id,
        user: this.user,
        password: this.password,
        OpcionPagoID: this.OpcionPagoID,
        MedioPagoID: this.MedioPagoID,
        url: this.url,
        type_integration: this.type_integration,
        cost_per_transaction: parseFloat(this.transaction_fee),
      })
      company.save().then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha creado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha guardado.',
          },
        },
        {
          position: 'top-center',
        })
        this.isNotifmsg = false
        this.notifmsg = []
        this.$emit('create-result')
        this.close()
      }).catch(error => {
        this.isNotifmsg = true
        this.notifmsg = error.response.data.errors
      })
    },
    updatePhoto() {
      const data = new FormData()
      data.append('photo', this.photo)
      this.$http.post(`/v1/companies/${this.id}/photo/`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Se ha actualizado correctamente.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    save() {
      this.$refs.CompanyForm.validate().then(async success => {
        if (success) {
          this.isNotifmsg = false
          this.notifmsg = []
          if (Object.keys(this.infoData).length > 0) {
            this.update()
          } else {
            this.create()
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>

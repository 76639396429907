<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="lg"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypeDocumentForm"
          >
            <b-form @submit.prevent>
              <vue-errors
                v-if="isNotifmsg"
                :notifmsg="notifmsg"
              />
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="typedocument-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        id="CompanyForm-name"
                        v-model="name"
                        name="CompanyForm-name"
                        placeholder="Nombre de la compañia"
                        :disabled="!$can('edit', 'type_document') || !$can('create', 'type_document')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Descripción"
                    label-for="typedocument-description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Descripción"
                      vid="Descripción"
                      rules=""
                    >
                      <b-form-input
                        id="typedocument-description"
                        v-model="description"
                        name="typedocument-name"
                        placeholder="Una breve descipción"
                        :disabled="!$can('edit', 'type_document') || !$can('create', 'type_document')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Estado"
                    label-for="typedocument-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <b-form-select
                        v-model="status_id"
                        :state="errors.length > 0 ? false : null"
                        :disabled="!$can('edit', 'type_document') || !$can('create', 'type_document')"
                        :options="optionsStatus"
                      >
                        <template #first>
                          <b-form-select-option :value="null">
                            Selecciona una opción
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Empresa"
                    label-for="StationBusForm-company_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Empresa"
                      rules="required"
                    >
                      <v-select
                        v-model="company_id"
                        label="name"
                        :options="optionsCompany"
                        :loading="loadingCompany"
                        :clearable="true"
                        placeholder="Empresa"
                        :disabled="!$can('edit', 'type_document') || !$can('create', 'type_document')"
                      >
                        <template #no-options="{}">
                          Sin datos.
                        </template>
                        <template v-slot:option="option">
                          <span :class="option.icon" />
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  v-if="company_id && company_id.type_integration == 'sisorg'"
                >
                  <b-form-group
                    label="Tipo de documento soap"
                    label-for="typedocument-soap"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo documento soap"
                      rules="required"
                    >
                      <v-select
                        v-model="homolog_id"
                        :get-option-label="city => city['@attributes'].Codigo"
                        :options="rowTypeDocuments"
                        :loading="loading"
                        :clearable="true"
                        placeholder="Tipo de documento soap"
                        :disabled="!$can('edit', 'type_document') || !$can('create', 'type_document')"
                      >
                        <template #no-options="{ }">
                          Sin datos.
                        </template>
                        <template v-slot:option="option">
                          <span :class="option.icon" />
                          {{ option['@attributes'].Codigo }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  v-if="company_id && company_id.type_integration == 'brasilia'"
                >
                  <b-form-group
                    label="Tipo de Documento"
                    label-for="typedocument-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipo de Documento"
                      vid="Tipo de Documento"
                      rules="required"
                    >
                      <b-form-input
                        id="CompanyForm-typedocument"
                        v-model="homolog_id"
                        name="CompanyForm-typedocument"
                        placeholder="Tipo de documento"
                        :disabled="!$can('edit', 'type_document') || !$can('create', 'type_document')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormSelect, BFormSelectOption, BModal,
} from 'bootstrap-vue'
import AlertErrors from '@/components/Errors/Alert.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Status from '@/libs/models/Status'
import TypeDocument from '@/libs/models/TypeDocument'
import Company from '@/libs/models/Company'
import vSelect from 'vue-select'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BModal,
    ValidationProvider,
    ValidationObserver,
    'vue-errors': AlertErrors,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      // data
      name: null,
      description: null,
      status_id: null,
      homolog_id: null,
      company_id: null,

      optionsStatus: [],
      rowTypeDocuments: [],
      optionsCompany: [],
      id: null,
      value: [],
      loading: false,
      loadingCompany: false,
      notifmsg: null,
      isNotifmsg: false,
      // validation rules
      required,
    }
  },
  computed: {
    title() {
      return Object.keys(this.infoData).length > 0 ? 'Editar Tipo de documento' : 'Crear Tipo de documento'
    },
  },
  watch: {
    company_id(value) {
      if (value.type_integration == 'sisorg') {
        this.getTypeDocumentSoap()
      }
    }
  },
  created() {
    if (this.$can('read', 'company')) {
      this.company_id = JSON.parse(localStorage.getItem('userData')).company
    }
    if (Object.keys(this.infoData).length > 0) {
      this.name = this.infoData.name
      this.description = this.infoData.description
      this.status_id = this.infoData.status_id
      this.company_id = this.infoData.company
      this.id = this.infoData.value
      this.homolog_id = this.infoData.homolog_id
    }
  },
  async mounted() {
    this.getOptionsStatus()
    this.getCompanies()
  },
  methods: {
    async getCompanies() {
      this.loadingCompany = true
      const { data } = await Company.where({
        type_integration_non_null: true
      }).get()
      this.optionsCompany = data
      this.loadingCompany = false
    },
    getTypeDocumentSoap() {
      this.loading = true
      this.$http.get('/v1/soap/documents', {
        params: {
          company_id: this.company_id ? this.company_id.id : null,
        },
      }).then(res => {
        this.rowTypeDocuments = res.data
        this.loading = false
        //eslint-disable-line
        if (Object.keys(this.infoData).length > 0) {
          //eslint-disable-line
          this.homolog_id = res.data.find(element => element['@attributes'].DocumentoID == this.infoData.homolog_id)
        }
      })
    },
    close() {
      this.$emit('close')
    },
    async getOptionsStatus() {
      const { data } = await Status.whereIn('id', [1, 2, 3, 4]).get()
      this.optionsStatus = data
    },
    async update() {
      //eslint-disable-line
      const model = await TypeDocument.find(this.id)
      model.name = this.name
      model.description = this.description
      model.status_id = this.status_id
      model.company_id = this.company_id ? this.company_id.id : null
      model.homolog_id = this.homolog_id
      if (this.company && this.company_id.type_integration == 'sisorg') {
        model.homolog_id = this.homolog_id ? this.homolog_id['@attributes'].DocumentoID : null
      }
      model.save().then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.close()
      }).catch(error => {
        this.isNotifmsg = true
        this.notifmsg = error.response.data.errors
      })
    },
    create() {
      let homolog_id = this.homolog_id
      if (this.company && this.company_id.type_integration == 'sisorg') {
        homolog_id = this.homolog_id ? this.homolog_id['@attributes'].DocumentoID : null
      }
      const company = new TypeDocument({
        name: this.name,
        description: this.description,
        status_id: this.status_id,
        company_id: this.company_id ? this.company_id.id : null,
        homolog_id: homolog_id,
      })
      company.save().then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha creado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha guardado.',
          },
        },
        {
          position: 'top-center',
        })
        this.isNotifmsg = false
        this.notifmsg = []
        this.$emit('create-result')
        this.close()
      }).catch(error => {
        this.isNotifmsg = true
        this.notifmsg = error.response.data.errors
      })
    },
    save() {
      this.$refs.TypeDocumentForm.validate().then(async success => {
        if (success) {
          this.isNotifmsg = false
          this.notifmsg = []
          if (Object.keys(this.infoData).length > 0) {
            this.update()
          } else {
            this.create()
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>

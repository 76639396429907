var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Terminales","active":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Integraciones","label-cols-md":"4"}},[_c('v-select',{attrs:{"label":"name","options":_vm.optionsCompany,"loading":_vm.loadingCompany,"clearable":true,"placeholder":"Selecciona una opción","disabled":!_vm.$can('edit', 'company') || !_vm.$can('create', 'company')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin datos. ")]}},{key:"option",fn:function(option){return [_c('span',{class:option.icon}),_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}})],1)],1)],1)],1),(_vm.company_id && _vm.company_id.type_integration == 'sisorg')?_c('b-col',{attrs:{"cols":"12"}},[_c('good-table',{ref:"gootable",attrs:{"model-data":_vm.stationbus,"columns":_vm.columns,"permissions":{
            create: _vm.$can('create', 'stationbus'),
            edit: _vm.$can('edit', 'stationbus'),
            show: !_vm.$can('read', 'stationbus'),
            delete: _vm.$can('delete', 'stationbus'),
            createImport: _vm.$can('create', 'stationbus')
          }},on:{"Create":_vm.Create,"Edit":_vm.EditRecord,"edit-select":_vm.editSelect,"delete":_vm.deleteRecord,"delete-select-all":_vm.deleteSelectAll,"import-data":_vm.importData,"selection-changed":_vm.selectionChanged}})],1):_vm._e(),(_vm.company_id && _vm.company_id.type_integration == 'brasilia')?_c('b-col',{attrs:{"cols":"12"}},[_c('good-table',{ref:"gootableBrasilia",attrs:{"model-data":_vm.stationbus,"columns":_vm.columns,"permissions":{
            create: _vm.$can('create', 'stationbus'),
            edit: _vm.$can('edit', 'stationbus'),
            show: !_vm.$can('read', 'stationbus'),
            delete: _vm.$can('delete', 'stationbus'),
            createImport: _vm.$can('create', 'stationbus')
          }},on:{"Create":_vm.Create,"Edit":_vm.EditRecord,"edit-select":_vm.editSelect,"delete":_vm.deleteRecord,"delete-select-all":_vm.deleteSelectAll,"import-data":_vm.importData,"selection-changed":_vm.selectionChanged}})],1):_vm._e()],1),(_vm.$can('delete', 'stationbus'))?_c('b-tab',{attrs:{"title":"Papelera"}},[(_vm.company_id)?_c('b-col',{attrs:{"cols":"12"}},[_c('good-table',{ref:"gootableTrashed",attrs:{"is-active-trash":true,"model-data":_vm.stationbus,"columns":_vm.columns,"permissions":{
            create: !_vm.$can('create', 'stationbus'),
            edit: !_vm.$can('edit', 'stationbus'),
            show: !_vm.$can('read', 'stationbus'),
            delete: !_vm.$can('delete', 'stationbus'),
            deleteRecovery: _vm.$can('delete', 'stationbus')
          }},on:{"Create":_vm.Create,"edit-select":_vm.editSelect,"delete-select-all":_vm.deleteSelectAll,"delete-recovery":_vm.deleteRecovery,"delete-recovery-select-all":_vm.deleteRecoverySelectAll,"selection-changed":_vm.selectionChanged}})],1):_vm._e()],1):_vm._e()],1),(_vm.modalShow)?_c('vue-stationbus-form',{attrs:{"modal-show":_vm.modalShow,"info-data":_vm.infoData},on:{"close":_vm.Create,"create-result":function($event){return _vm.$refs.gootable.loadItems()},"edit-result":function($event){return _vm.$refs.gootable.loadItems()}}}):_vm._e(),(_vm.modalImportShow)?_c('vue-import-form',{attrs:{"modal-show":_vm.modalImportShow,"company_id":_vm.company_id},on:{"import-complete":_vm.importComplete,"close":_vm.importComplete}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
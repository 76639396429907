<template>
  <div>
    <b-tabs>
      <b-tab
        title="Terminales"
        active
      >
        <b-col cols="12">
          <b-card>
            <b-col cols="12">
              <b-form-group
                label="Integraciones"
                label-cols-md="4"
              >
                <v-select
                  v-model="company_id"
                  label="name"
                  :options="optionsCompany"
                  :loading="loadingCompany"
                  :clearable="true"
                  placeholder="Selecciona una opción"
                  :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                >
                  <template #no-options="{ }">
                    Sin datos.
                  </template>
                  <template v-slot:option="option">
                    <span :class="option.icon" />
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>
        <b-col cols="12" v-if="company_id && company_id.type_integration == 'sisorg'">
          <good-table
            ref="gootable"
            :model-data="stationbus"
            :columns="columns"
            :permissions="{
              create: $can('create', 'stationbus'),
              edit: $can('edit', 'stationbus'),
              show: !$can('read', 'stationbus'),
              delete: $can('delete', 'stationbus'),
              createImport: $can('create', 'stationbus')
            }"
            @Create="Create"
            @Edit="EditRecord"
            @edit-select="editSelect"
            @delete="deleteRecord"
            @delete-select-all="deleteSelectAll"
            @import-data="importData"
            @selection-changed="selectionChanged"
          />
        </b-col>
        <b-col cols="12" v-if="company_id && company_id.type_integration == 'brasilia'">
          <good-table
            ref="gootableBrasilia"
            :model-data="stationbus"
            :columns="columns"
            :permissions="{
              create: $can('create', 'stationbus'),
              edit: $can('edit', 'stationbus'),
              show: !$can('read', 'stationbus'),
              delete: $can('delete', 'stationbus'),
              createImport: $can('create', 'stationbus')
            }"
            @Create="Create"
            @Edit="EditRecord"
            @edit-select="editSelect"
            @delete="deleteRecord"
            @delete-select-all="deleteSelectAll"
            @import-data="importData"
            @selection-changed="selectionChanged"
          />
        </b-col>
      </b-tab>
      <b-tab
        v-if="$can('delete', 'stationbus')"
        title="Papelera"
      >
        <b-col cols="12" v-if="company_id">
          <good-table
            ref="gootableTrashed"
            :is-active-trash="true"
            :model-data="stationbus"
            :columns="columns"
            :permissions="{
              create: !$can('create', 'stationbus'),
              edit: !$can('edit', 'stationbus'),
              show: !$can('read', 'stationbus'),
              delete: !$can('delete', 'stationbus'),
              deleteRecovery: $can('delete', 'stationbus')
            }"
            @Create="Create"
            @edit-select="editSelect"
            @delete-select-all="deleteSelectAll"
            @delete-recovery="deleteRecovery"
            @delete-recovery-select-all="deleteRecoverySelectAll"
            @selection-changed="selectionChanged"
          />
        </b-col>

      </b-tab>
    </b-tabs>
    <vue-stationbus-form
      v-if="modalShow"
      :modal-show="modalShow"
      :info-data="infoData"
      @close="Create"
      @create-result="$refs.gootable.loadItems()"
      @edit-result="$refs.gootable.loadItems()"
    />
    <vue-import-form
      v-if="modalImportShow"
      :modal-show="modalImportShow"
      :company_id="company_id"
      @import-complete="importComplete"
      @close="importComplete"
    />
  </div>
</template>
<script>
import {
  BCol, BTabs, BTab, BRow, BFormGroup, BFormInput, BCard,
} from 'bootstrap-vue'
import GoodTable from '@/components/Table/Index.vue'
import Form from '@/components/StationBus/Form.vue'
import FormImport from '@/components/StationBus/FormImport.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { columnsValue } from '@/components/StationBus/field/index'
import StationBus from '@/libs/models/StationBus'
import Company from '@/libs/models/Company'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BTabs,
    BTab,
    BRow,
    BCard,
    BFormGroup,
    BFormInput,
    GoodTable,
    'vue-stationbus-form': Form,
    'vue-import-form': FormImport,
    vSelect,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      show: false,
      selectRow: null,
      selecction: [],
      infoData: {},
      searchTerm: null,
      UsersData: {},
      optionsCompany: [],
      company_id: null,
      searchCompanyid: null,
      loadingCompany: false,

      modalShow: false,
      modalImportShow: false,
      columns: [],
      stationbus: StationBus,
    }
  },
  async mounted() {
    this.getCompanies()
  },
  watch: {
    async company_id(newVal) {
      this.columns = []
      if (newVal.type_integration === 'sisorg') {
        this.columns = await columnsValue()
        if (this.$can('delete', 'stationbus') || this.$can('edit', 'stationbus')) {
          this.columns.unshift({
            label: 'Acciones',
            field: 'action',
            sortable: false,
          })
        }
        if (this.$refs.gootable) {
          this.$refs.gootable.setFilterAdicional({
            'company.id': newVal.id,
          })
          this.$refs.gootableTrashed.setFilterAdicional({
            'company.id': newVal.id,
          })
          this.$refs.gootable.loadItems()
          this.$refs.gootableTrashed.loadItems()

        }
      } else if (newVal.type_integration === 'brasilia') {
        this.columns = await columnsValue()
        if (this.$can('delete', 'stationbus') || this.$can('edit', 'stationbus')) {
          this.columns.unshift({
            label: 'Acciones',
            field: 'action',
            sortable: false,
          })
        }
        if (this.$refs.gootableBrasilia) {
          this.$refs.gootableBrasilia.setFilterAdicional({
            'company.id': newVal.id,
          })
          this.$refs.gootableBrasilia.loadItems()
          this.$refs.gootableTrashed.loadItems()
        }
      }
    },
  },
  methods: {
    async getCompanies() {
      this.loadingCompany = true
      const { data } = await Company
                              .where({
                                'status.id': 1,
                              })
                              .get()
      this.optionsCompany = data
      this.loadingCompany = false
    },
    Create() {
      this.infoData = {}
      this.modalShow = !this.modalShow
    },
    EditRecord(data) {
      this.infoData = data
      this.modalShow = !this.modalShow
    },
    editSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = !this.modalShow
      }
    },
    importData() {
      this.modalImportShow = !this.modalImportShow
    },
    importComplete() {
      this.$refs.gootable.loadItems()
      this.modalImportShow = !this.modalImportShow
    },
    deleteRecord(val) {
      this.$http.delete(`/v1/stationbus/${val.id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$refs.gootableTrashed.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteSelectAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/stationbus/many', {
          ids: this.selecction.map(element => element.id),
        }).then(res => {//eslint-disable-line
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
          this.$refs.gootable.loadItems()
          this.$refs.gootableTrashed.loadItems()
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    deleteRecovery(val) {
      this.$http.post('/v1/stationbus/recovery/many', {
        ids: [val.id],
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha restaurado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha Restaurado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$refs.gootableTrashed.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteRecoverySelectAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton restaurar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/stationbus/recovery/many', {
          ids: this.selecction.map(element => element.id),
          }).then(res => {//eslint-disable-line
          this.$refs.gootable.loadItems()
          this.$refs.gootableTrashed.loadItems()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha Restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'El registro se ha Restaurado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>

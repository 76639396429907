<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="lg"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-overlay
      id="overlay-background"
      :show="showOverlay"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
      rounded="sm"
    >
      <b-container fluid>
        <b-row>
          <b-col
            cols="12"
          >
            <validation-observer
              ref="importForm"
            >
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Empresa"
                      label-for="importForm-company_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Empresa"
                        rules="required"
                      >
                        <v-select
                          v-model="company_default_id"
                          label="name"
                          :options="optionsCompany"
                          :loading="loadingCompany"
                          :clearable="true"
                          placeholder="Selecciona empresa a la que importar los datos"
                          :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                        >
                          <template #no-options="{ }">
                            Sin datos.
                          </template>
                          <template v-slot:option="option">
                            <span :class="option.icon" />
                            {{ option.name }}
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="float-right ml-1"
          variant="primary"
          :disabled="showOverlay"
          @click="save()"
        >
          <b-spinner
            v-if="showOverlay"
            small
            type="grow"
          />
          <span class="align-middle">Importar</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          :disabled="showOverlay"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BForm, BButton, BContainer, BOverlay, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Company from '@/libs/models/Company'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BOverlay,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    company_id: {
      type: Object,
      required: true,
      default: () => {},
    },
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      optionsCompany: [],
      loadingCompany: false,

      showOverlay: false,
      variant: 'light',
      opacity: 0.85,
      blur: '2px',
      // validation rules
      required,
    }
  },
  computed: {
    title() {
      return 'Importar Terminales'
    },
    company_default_id: {
      get() {
        return this.company_id
      },
      set(val) {
        return val
      },
    },
  },
  created() {
    if (this.$can('read', 'company')) {
      this.company_default_id = JSON.parse(localStorage.getItem('userData')).company
    }
  },
  async mounted() {
    this.getCompanies()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getCompanies() {
      this.loadingCompany = true
      const { data } = await Company.where({
        id: this.company_default_id ? this.company_default_id.id : null
      }).get()
      this.optionsCompany = data
      this.loadingCompany = false
    },
    save() {
      this.$refs.importForm.validate().then(async success => {
        if (success) {
          this.showOverlay = true
          this.$http.post('/v1/travel/importar/terminales', {
            company_id: this.company_default_id ? this.company_default_id.id : null,
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Proceso de importación realizado con exito',
              },
            },
            {
              position: 'top-center',
            })
            this.$emit('import-complete')
            this.showOverlay = false
          }).catch(error => {
            this.showOverlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>

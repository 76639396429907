<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="xl"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="4">
          <b-card
            class="card-developer-meetup mb-0"
          >
            <b-card-title>Información del viaje</b-card-title>
            <hr>
            <b-card-body class="p-1">
              <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                  <h6 class="mb-0">
                    {{ setDay(travelData.departure_date) }}
                  </h6>
                  <h3 class="mb-0">
                    {{ setDate(travelData.departure_date) }}
                  </h3>
                </div>
                <div class="my-auto">
                  <b-card-title class="mb-25">
                    <b>Id ticket:</b> {{ travelData.id }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    <b>Origen:</b> {{ travelData.source }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Destino:</b> {{ travelData.destination }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Fecha de salida (Aprox):</b> {{ travelData.departure_date }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Fecha de llegada (Aprox):</b> {{ travelData.arrival_date }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Sillas:</b> {{ this.getSillas(travelData.chairs_select) }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Actualizar el estado del ticket:</b>
                    <b-form-checkbox
                      v-model="changeStatusTicket"
                      switch
                    />
                  </b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="8">
          <good-table
            ref="gootable"
            :model-data="paymentTransactionHistory"
            :filterOptional="{
              ticket_purchase_history_id: travelData.id
            }"
            :columns="columns"
            :permissions="{}"
            @edit-status="editStatus"
            @selection-changed="selectionChanged"
          />
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BContainer, BModal, BCard, BCol, BRow, BCardBody, BCardTitle, BCardText, BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GoodTable from '@/components/Table/Index.vue'
import PaymentTransactionHistory from '@/libs/models/PaymentTransactionHistory'
import { columnsValue } from '@/components/HistoryTravel/field/fieldsPaymentTransaction'
import moment from 'moment'
import 'moment/locale/es'

export default {
  components: {
    BContainer,
    BButton,
    BModal,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    GoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    travelData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      // data
      columns: [],
      changeStatusTicket: false,
      paymentTransactionHistory: PaymentTransactionHistory,
    }
  },
  computed: {
    title() {
      return 'Cambiar estado de la transacción'
    },
  },
  async mounted() {
    this.columns = await columnsValue()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    editStatus(data) {
      this.$http.put(`/v1/travel/payment-transaction/${data.row.id}/change-status`, {
        status_id: data.row.status.id,
        ticket_change: this.changeStatusTicket,
        ticket_purchase_history_id: data.row.ticket_purchase_history_id,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cambio de estado',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Ha sido cambiado correctamente',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$emit('update-table')
      }).catch(error => {
        data.row.edit = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          timeout: 5000,
          position: 'bottom-right',
        })
      })
    },
    setDate(value) {
      const data = moment(value, 'DD-MM-YYYY')
      return data.date()
    },
    setDay(value) {
      const data = moment(value, 'DD-MM-YYYY')
      return data.format('dddd').slice(0, 3).toUpperCase()
    },
    getSillas(sillas) {
      return sillas.map(item => item.label).toString()
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm","variant":"white","spinner-variant":"primary","blur":"0","opacity":".75"}},[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Integraciones","active":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('good-table',{ref:"gootable",attrs:{"model-data":_vm.company,"columns":_vm.columns,"permissions":{
              create: _vm.$can('create', 'company'),
              edit: _vm.$can('edit', 'company'),
              show: !_vm.$can('read', 'company'),
              delete: _vm.$can('delete', 'company')
            }},on:{"Create":_vm.Create,"Edit":_vm.Edit,"edit-select":_vm.editSelect,"delete":_vm.deleteRecord,"delete-select-all":_vm.deleteSelectAll,"selection-changed":_vm.selectionChanged}})],1)],1),(_vm.$can('delete', 'company'))?_c('b-tab',{attrs:{"title":"Papelera"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('good-table',{ref:"gootableTrashed",attrs:{"is-active-trash":true,"model-data":_vm.company,"columns":_vm.columns,"permissions":{
              create: !_vm.$can('create', 'company'),
              edit: !_vm.$can('edit', 'company'),
              show: !_vm.$can('read', 'company'),
              delete: !_vm.$can('delete', 'company'),
              deleteRecovery: _vm.$can('delete', 'company')
            }},on:{"Create":_vm.Create,"edit-select":_vm.editSelect,"delete-select-all":_vm.deleteSelectAll,"delete-recovery":_vm.deleteRecovery,"delete-recovery-select-all":_vm.deleteRecoverySelectAll,"selection-changed":_vm.selectionChanged}})],1)],1):_vm._e()],1)],1),(_vm.modalShow)?_c('vue-company-form',{attrs:{"modal-show":_vm.modalShow,"info-data":_vm.infoData},on:{"close":_vm.Create,"create-result":function($event){return _vm.$refs.gootable.loadItems()},"edit-result":function($event){return _vm.$refs.gootable.loadItems()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
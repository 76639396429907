<template>
  <b-card :title="titleTable">
    <vue-good-table
      mode="remote"
      :is-loading.sync="isLoadingActive"
      row-style-class="text-sm"
      :pagination-options="{
        enabled: true,
        position: 'both',
      }"
      :select-options="{
        enabled: selectRow,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'registro seleccionado',
        clearSelectionText: 'Limpiar',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :total-rows="totalRecords"
      :rows="rows"
      :columns="columns"
      :line-numbers="true"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="emptystate">
        No existen registros
      </div>
      <div
        v-if="showActions"
        slot="table-actions"
      >
        <b-button-toolbar
          justify
        >
          <p class="mx-1">
            <strong>Acciones:</strong>
          </p>
          <b-button-group class="mr-1">
            <b-button
              v-if="permissions.create"
              v-b-tooltip.hover.top="'Crear registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('Create')"
            >
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-button
              v-if="permissions.edit"
              v-b-tooltip.hover.top="'Editar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('edit-select')"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              v-if="permissions.delete"
              v-b-tooltip.hover.top="'Eliminar registros'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('delete-select-all')"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-if="permissions.show"
              v-b-tooltip.hover.top="'Ver registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('show-select')"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="permissions.deleteRecovery"
              v-b-tooltip.hover.top="'Restaurar registros'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('delete-recovery-select-all')"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              v-if="permissions.createImport"
              v-b-tooltip.hover.top="'Importar registros desde el api soap'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('import-data')"
            >
              <feather-icon icon="DatabaseIcon" />
            </b-button>
            <b-button
              v-if="permissions.showChairsSelect"
              v-b-tooltip.hover.top="'Mostrar datos de las sillas seleccionadas'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('show-chairs-select')"
            >
              <feather-icon icon="LayersIcon" />
            </b-button>
            <b-button
              v-if="permissions.showTransaction"
              v-b-tooltip.hover.top="'Mostrar todos pagos que se recibieron en el ticket'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('show-transaction-select')"
            >
              <feather-icon icon="DollarSignIcon" />
            </b-button>
            <b-button
              v-if="permissions.showTicket"
              v-b-tooltip.hover.top="'Ir al tiquete'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$emit('show-ticket-select')"
            >
              <feather-icon icon="ExternalLinkIcon" />
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-sm-1">
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'name' && props.column.type === 'photo'"
          class="text-center"
        >
          <b-avatar
            size="70px"
            :src="setAvatar(props.row.photo)"
          />
          {{ props.row.name }}
        </span>
        <span
          v-else-if="props.column.type === 'text_json'"
          class="text-center"
        >
          <vue-json-pretty
            :data="JSON.parse(props.row.data_of_payment)"
            :show-double-quotes="false"
            :show-key-value-space="true"
            :set-path-collapsible="true"
            :show-icon="true"
            :show-length="true"
            :deep="4"
            :virtual="true"
            :height="200"
          />
        </span>
        <span
          v-else-if="props.column.type === 'text_less'"
          class="text-center"
        >
          {{ props.row.description.substring(0, 40) }} ...
        </span>
        <span
          v-else-if="props.column.field === 'status.id' && props.column.type === 'edit-status'"
          class="text-nowrap"
        >
          <template
            v-if="!props.row.edit"
          >
            <b-badge
              class="mr-1"
              pill
              :variant="getStatus(props.row.status.name)"
            >
              {{ props.row.status.name }}
            </b-badge>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Editar estado'"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              @click="props.row.edit = true"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>
          <template
            v-else
          >
            <b-form-select
              v-model="props.row.status.id"
              :disabled="!$can('edit', 'payment_transaction_history')"
              :options="optionsStatus"
              @change="editStatus(props.row)"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Selecciona
                </b-form-select-option>
              </template>
            </b-form-select>
          </template>
        </span>
        <span
          v-else-if="props.column.field === 'status.id'"
          class="text-nowrap"
        >
          <b-badge
            pill
            :variant="getStatus(props.row.status.name)"
          >
            {{ props.row.status.name }}
          </b-badge>
        </span>
        <span
          v-else-if="props.column.field === 'type_payment.id'"
          class="text-nowrap"
        >
          <template v-if="props.row.type_payment">
            {{ props.row.type_payment.name }}
          </template>
        </span>
        <span
          v-else-if="props.column.field === 'chairs_select'"
          class="text-nowrap"
        >
          <template v-if="props.row.chairs_select">
            {{ setSeatsSelect(props.row.chairs_select) }}
          </template>
        </span>
        <!-- Column: Action -->
        <template v-else-if="props.column.field === 'action'">
          <span>
            <b-button
              v-if="permissions.edit"
              v-b-tooltip.hover.top="'Editar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('Edit', props.row)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              v-if="permissions.delete"
              v-b-tooltip.hover.top="'Eliminar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('delete', props.row)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-if="permissions.deleteRecovery"
              v-b-tooltip.hover.top="'Restaurar registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('delete-recovery', props.row)"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              v-if="permissions.show"
              v-b-tooltip.hover.top="'Ver registro'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('show', props.row)"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button
              v-if="permissions.showChairsSelect"
              v-b-tooltip.hover.top="'Mostrar datos de las sillas seleccionadas'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('show-chairs', props.row)"
            >
              <feather-icon icon="LayersIcon" />
            </b-button>
            <b-button
              v-if="permissions.showTransaction"
              v-b-tooltip.hover.top="'Mostrar todos pagos que se recibieron en el tiquete'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('show-transaction', props.row)"
            >
              <feather-icon icon="DollarSignIcon" />
            </b-button>
            <b-button
              v-if="permissions.showTicket"
              v-b-tooltip.hover.top="'Ir al tiquete'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat"
              class="btn-icon"
              @click="$emit('show-ticket', props.row)"
            >
              <feather-icon icon="ExternalLinkIcon" />
            </b-button>
          </span>
        </template>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-top"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap b-b">
          <div class="d-flex align-items-center mb-1 mt-1 ml-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({ currentPerPage: value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registro </span>
          </div>
          <div>
            <b-pagination
              v-model="valuePagination"
              :value="valuePagination"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0 mr-1"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap b-b">
          <div class="d-flex align-items-center mb-1 mt-1 ml-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registro </span>
          </div>
          <div>
            <b-pagination
              v-model="valuePagination"
              :value="valuePagination"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0 mr-1"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BButtonToolbar, BButtonGroup, BButton, BTooltip, VBTooltip, BAvatar, BBadge, BFormSelect, BPagination, BDropdown, BDropdownItem, BFormSelectOption,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import Status from '@/libs/models/Status'
// import { FormatDate } from '@core/utils/filter'
// import moment from 'moment'
/* eslint-disable */
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BCard,
    BBadge,
    BFormSelect,
    BTooltip,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelectOption,
    VueGoodTable,
    VueJsonPretty
  },
  props: {
    url:  {
      type: String,
      required: false,
      default: () => '/v1/users/all/1/',
    },
    modelData: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    titleTable: {
      type: String,
      required: false,
      default: () => '',
    },
    selectRow: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    showActions: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    filterOptional: {
      type: Object,
      required: false,
      default: () => {},
    },
    permissions: {
      type: Object,
      required: false,
      default: {
        create: false,
        edit: false,
        show: false,
        delete: false,
        deleteRecovery: false,
        createImport: false,
        showChairsSelect: false,
        showTransaction: false,
        showTicket: false
      },
    },
    sortDefault: {
      type: Array,
      required: false,
      default: () => [],
    },
    isActiveSort: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActiveTrash: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup() {
    return {
      // FormatDate,
    }
  },
  data() {
    return {
      pageLength: 10,
      valuePagination: 1,
      isLoading: false,
      rows: [],
      totalRecords: 0,
      optionsStatus: [],
      filterAditional: {},
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    isLoadingActive: {
      get() {
        return this.isLoading
      },
      set(val) {
        return val
      },
    },
  },
  mounted() {
    if (this.isActiveTrash) {
      this.onFilterTrash()
    }

    if (this.isActiveSort) {
      this.onSortDefault()
    }
    this.loadItems()
    this.getOptionsStatus()
  },
  methods: {
    editStatus(row, column) {
      row.edit = true
      this.$emit('edit-status', {
        row: row,
        column: column
      })
    },
    getStatus(value) {
      const statusColor = {
        /* eslint-disable key-spacing */
        ACTIVADO      : 'success',
        DESACTIVADO : 'danger',
        BLOQUEADO     : 'danger',
        PENDIENTE     : 'warning',
        DECLINADO      : 'warning',
        APROBADO      : 'success',
        ANULADO      : 'danger',
        ERROR      : 'danger',
        /* eslint-enable key-spacing */
      }
      return statusColor[value]
    },
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(value)
    },
    selectionChanged(params) {
      this.$emit('selection-changed', params)
    },
    setAvatar(value) {
      return `${process.env.VUE_APP_URL_STORAGE}${value}`
    },
    updateParams(newProps) {
      // eslint-disable-next-line prefer-object-spread
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      const sort = params.map(param => param.type === 'desc' ? `-${param.field}` : param.field);
      this.updateParams({
        sort: sort,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    onFilterTrash() {
      this.updateParams({
        columnFilters: {
          trashed: "only"
        }
      })
    },

    onSortDefault() {
      const sort = this.sortDefault.map(param => param.type === 'desc' ? `-${param.field}` : param.field);
      this.updateParams({
        sort: sort,
      })
    },

    setSeatsSelect(seats) {
      return seats.map(element => element.label).toString()
    },

    setFilterAdicional(filter) {
      this.filterAditional = filter
    },

    async getOptionsStatus() {
      const { data } = await Status.get()
      this.optionsStatus = data
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isLoading = true
      const data = await this.modelData
                  .page(this.serverParams.page)
                  .limit(parseInt(this.serverParams.perPage))
                  .where(this.serverParams.columnFilters)
                  .where(this.filterOptional ?? null)
                  .where(this.filterAditional ?? null)
                  .orderBy(this.serverParams.sort)
                  .get()
      this.rows = data.data
      this.totalRecords = data.meta.total
      this.isLoading = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.text-sm {
  font-size: 14px;
}
.text-sm-1 {
  font-size: 15px;
}

.dropdown-menu {
  max-height: 170px;
  overflow: auto;
}
</style>

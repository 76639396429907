import Status from '@/libs/models/Status'
import Company from '@/libs/models/Company'

const filterOptions = async () => {
  try {
    const response = await Status.whereIn('id', [1, 2, 3, 4]).get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

const filterOptionsCompany = async () => {
  try {
    const response = await Company
                          .where({
                            'status.id': 1,
                          }).get()
    return response.data
  } catch (err) {
    console.error(err)
    return err
  }
}

async function columnsValue() {
  const columns = [
    {
      label: 'Estado',
      field: 'status.id',
      tdClass: 'text-center',
      width: '230px',
      sortable: false,
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Selecciona un estado',
        filterDropdownItems: await filterOptions(),
      },
    },
    {
      label: 'Motivo',
      field: 'motive',
      tdClass: 'text-center',
      width: '230px',
      sortable: false,
    },
    {
      label: 'Nombre',
      field: 'name',
      firstSortType: 'asc',
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Codigo',
      field: 'code_homolog_soap',
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Ciudad asociada',
      field: 'city.name',
      width: '190px',
      sortable: false,
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
  ]
  return columns
}

export { columnsValue, filterOptions }
